const de = {
  translation: {
    "Sign in credentials": "Anmeldeinformationen",
    "Sign in": "Einloggen",
    "Add Category": "Kategorie hinzufügen",
    Title: "Titel",
    Description: "Beschreibung",
    Add: "Hinzufügen",
    Remove: "Löschen",
    "Add/Remove": "Hinzufügen/Entfernen",
    Success: "Erfolg",
    Danger: "Achtung",
    Categories: "Kategorien",
    Image: "Bild",
    Header: "Header",
    Loading: "Wird geladen",
    Error: "Error",
    Edit: "Bearbeiten",
    Delete: "Löschen",
    "Edit Category": "Kategorie bearbeiten",
    Save: "sparen",
    "OrderID prefix": "OrderID-Präfix",
    Order: "Auftrag",
    Email: "Email",
    Password: "Passwort",
    Enable: "Aktivieren",
    Disable: "Deaktivieren",
    "Enable/Disable": "Aktivieren deaktivieren",
    Saving: "Speichern",
    Paypal: "Paypal",
    "Client ID": "Kunden ID",
    "Client Secret": "Kundengeheimnis",
    Sandbox: "Sandkasten",
    "Publishable Key": "Veröffentlichbarer Schlüssel",
    "Secret Key": "Geheimer Schlüssel",
    "Delivery Charges": "Versandkosten",
    Price: "Preis",
    "Add Food": "Lebensmittel hinzufügen",
    Category: "Kategorie",
    Select: "Wählen",
    Variations: "Variationen",
    Type: "Art",
    "Food Image": "Food Image",
    Foods: "Lebensmittel",
    "Edit Food": "Essen bearbeiten",
    OrderID: "Auftragsnummer",
    Orders: "Aufträge",
    Name: "Name",
    Items: "Artikel",
    Payment: "Zahlung",
    Status: "Status",
    Review: "Rezension",
    "Mark as": "markieren als",
    Users: "Benutzer",
    Phone: "Telefon",
    Address: "Adresse",
    "2019-20": "2019-20",
    "About Us": "Über uns",
    Blog: "Blog",
    Welcome: "Herzlich willkommen",
    Logout: "Ausloggen",
    Dashboard: "Instrumententafel",
    Configuration: "Aufbau",
    Login: "Anmeldung",
    Food: "Essen",
    "Character limit of max length 50":
      "Zeichenbegrenzung von maximaler Länge 50",
    "Character limit of max length 15":
      "Zeichenbegrenzung von maximaler Länge 15",
    "Character limit of max length 60":
      "Zeichenbegrenzung von maximaler Länge 60",
    "Character limit of max length 20":
      "Zeichenbegrenzung von maximaler Länge 20",
    "Character limit of max length 140":
      "Zeichenbegrenzung von maximaler Länge 140",
    Currency: "Währung",
    "Currency Code": "Währungscode",
    "Currency Symbol": "Währungszeichen",
    Notifications: "Benachrichtigungen",
    purchaseText: "Die Löschfunktion ist nach dem Kauf des Produkts verfügbar",
    code: "Code",
    discount: "Rabatt %",
    totalOrders: "Gesamtbestellungen",
    totalUsers: "Gesamtbenutzer",
    totalSales: "Gesamtumsatz",
    averageTotal: "Durchschnittliche Bewertungen/Gesamtbewertungen",
    filterGraph: "Diagramm filtern",
    startingDate: "Startdatum",
    submit: "Einreichen",
    overview: "Übersicht",
    performance: "Leistung",
    endingDate: "Enddatum",
    salesValue: "Verkaufswert",
    salesAmount: "Verkaufsbetrag",
    orderCount: "Bestellanzahl",
    categoryUpdated: "Kategorie erfolgreich aktualisiert",
    categoryAdded: "Kategorie erfolgreich hinzugefügt",
    actionFailed: "Aktion fehlgeschlagen. Bitte versuchen Sie es erneut",
    egBreakfast: "z.B. Frühstück",
    egAllHappiness: "z.B. Das gesamte Glück hängt von einem gemütlichen Frühstück ab.",
    newAddon: 'Neues Addon',
    uniqueTitle: 'Titel muss einzigartig sein',
    egSmall: 'z.B. Klein',
    foodUpdated: "Speise erfolgreich aktualisiert",
    foodAdded: "Speise erfolgreich hinzugefügt",
    Addons: "Zusatzoptionen",
    Options: "Optionen",
    selectOneOption: "Wählen Sie mindestens eine Option aus",
    new: "Neu",
    addRemoveAddons: "Zusatzoptionen hinzufügen/entfernen",
    quantityMinimum: "Mindestmenge",
    quantityMinText: "Muss kleiner oder gleich dem Maximum sein",
    quantityMaximum: "Höchstmenge",
    quantityMaxText: "Muss größer oder gleich dem Maximum sein",
    egPepsi: "z. B. Pepsi",
    egOptional: "z. B. Optional",
    option: "Option",
    mustBeANumber: "Muss eine Zahl sein",
    egTextOption: "z. B. 90.25",
    saved: "Gespeichert",
    errorOccured: "Beim Speichern ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    discounted: "Rabattiert",
    eg99: "z. B. 9.99",
    eg9: "z. B. 9",
    filterByOrderId: "Filtern nach Bestellnummer",
    datetime: "Datum und Uhrzeit",
    stock: "Bestand",
    Ratings: "Bewertungen",
    Riders: "Fahrer",
    addRider: "Fahrer hinzufügen",
    username: "Benutzername",
    available: "Verfügbar",
    riderUpdatedText: "Fahrer erfolgreich aktualisiert",
    riderAddedText: "Fahrer erfolgreich hinzugefügt",
    actions: "Aktion",
    editRider: "Fahrer bearbeiten",
    Option: "Option",
    minimum: "Minimum",
    maximum: "Maximum",
    Coupons: "Gutscheine",
    couponUpdatedText: "Gutschein aktualisiert",
    couponAddedText: "Gutschein hinzugefügt",
    actionFailedError: "Aktion fehlgeschlagen. Bitte versuchen Sie es erneut",
    editCoupon: "Gutschein bearbeiten",
    addCoupon: "Gutschein hinzufügen",
    couponCode: "Gutscheincode",
    discountPercent: "Rabatt Prozent",
    btw1And100: "Zwischen 1 und 100",
    enableDisable: "Aktiviert/Deaktiviert",
    egSales: "z.B. SALE50",
    body: 'Körper',
    egHello: "z.B. Hallo",
    send: "Senden",
    ReasonIfRejected: 'Reden indien afgewezen',
    Accepted: 'Geaccepteerd',
    Accept: 'Accepteren',
    Cancelled: 'Geannuleerd',
    Cancel: 'Annuleren',
    Assign: 'Toewijzen',
    ShowHide: 'Tonen/Verbergen',
    PaymentMethod: 'Betalingsmethode',
    PaidAmount: 'Betaald Bedrag',
    AvailableRiders: 'Beschikbare Bezorgers',
    Rider: 'Bezorger',
    AssignedTo: 'Toegewezen aan',
    SelectStatus: 'Selecteer Status',
    OrderStatus: 'Bestelstatus',
    CurrentStatus: 'Huidige Status',
    PaymentStatus: 'Betalingsstatus',
    Customer: 'Klant',
    OrderDetails: 'Besteldetails',
    Charges: 'Kosten',
    DeliveryCharges: 'Leveringskosten',
    Subtotal: 'Subtotaal',
    Total: 'Totaal',
    Admin: 'Beheerder',
    Stripe: 'Stripe',
    PHRiderName: 'Jan Jansen',
    PHUserName: 'JanJansen1122',
  },
};

export default de;
