const km = {
  translation: {
    "Sign in credentials": "ចូលព័ត៌មានអត្តសញ្ញាណ",
    "Sign in": "ចូល",
    "Add Category": "បន្ថែមប្រភេទ",
    Title: "ចំណងជើង",
    Description: "ការពិពណ៌នា",
    Add: "បន្ថែម",
    Remove: "យកចេញ",
    "Add/Remove": "បន្ថែម / យកចេញ",
    Success: "ជោគជ័យ",
    Danger: "គ្រោះថ្នាក់",
    Categories: "ប្រភេទ",
    Image: "រូបភាព",
    Header: "បឋមកថា",
    Loading: "កំពុងផ្ទុក",
    Error: "កំហុស",
    Edit: "កែប្រែ",
    Delete: "លុប",
    "Edit Category": "កែសម្រួលប្រភេទ",
    Save: "រក្សាទុក",
    "OrderID prefix": "បុព្វបទ ID លំដាប់",
    Order: "លំដាប់",
    Email: "អ៊ីមែល",
    Password: "ពាក្យសម្ងាត់",
    Enable: "អនុញ្ញាត",
    Disable: "បិទ",
    "Enable/Disable": "អនុញ្ញាត / មិនអនុញ្ញាត",
    Saving: "កំពុងរក្សាទុក",
    Paypal: "Paypal",
    "Client ID": "លេខសម្គាល់អតិថិជន",
    "Client Secret": "អតិថិជនសម្ងាត់",
    Sandbox: "Sandbox",
    "Publishable Key": "កូនសៀវភៅបោះពុម្ព",
    "Secret Key": "កូនសៀវភៅបោះពុម្ព",
    "Delivery Charges": "ការដឹកជញ្ជូន",
    Price: "តម្លៃ",
    "Add Food": "បន្ថែមអាហារ",
    Category: "ប្រភេទ",
    Select: "ជ្រើសរើស",
    Variations: "បំរែបំរួល",
    Type: "វាយ",
    "Food Image": "រូបភាពអាហារ",
    Foods: "អាហារ",
    "Edit Food": "កែសម្រួលអាហារ",
    OrderID: "លេខ​សម្គាល់​លំដាប់",
    Orders: "ការបញ្ជាទិញ",
    Name: "ឈ្មោះ",
    Items: "ធាតុ",
    Payment: "ការទូទាត់",
    Status: "ស្ថានភាព",
    Review: "ពិនិត្យឡើងវិញ",
    "Mark as": "សម្គាល់ថា",
    Users: "អ្នកប្រើ",
    Phone: "ទូរស័ព្ទ",
    Address: "អាសយដ្ឋាន",
    "2019-20": "ពីរ​ពាន់​ដប់​ប្រាំបួន",
    "About Us": "អំពី​ពួក​យើង",
    Blog: "កំណត់ហេតុបណ្ដាញ",
    Welcome: "ស្វាគមន៍",
    Logout: "ចាកចេញ",
    Dashboard: "ផ្ទាំងគ្រប់គ្រង",
    Configuration: "ការកំណត់​រចនាសម្ព័ន្ធ",
    Login: "ចូល",
    Food: "អាហារ",
    "Character limit of max length 50": "ដែនកំណត់តួអក្សរនៃប្រវែងអតិបរមា 50",
    "Character limit of max length 15": "ដែនកំណត់តួអក្សរនៃប្រវែងអតិបរមា 15",
    "Character limit of max length 60": "ដែនកំណត់តួអក្សរនៃប្រវែងអតិបរមា 60",
    "Character limit of max length 20": "ដែនកំណត់តួអក្សរនៃប្រវែងអតិបរមា 20",
    "Character limit of max length 140": "ដែនកំណត់តួអក្សរនៃប្រវែងអតិបរមា 140",
    Currency: "រូបិយប័ណ្ណ",
    "Currency Code": "លេខកូដរូបិយប័ណ្ណ",
    "Currency Symbol": "និមិត្តសញ្ញារូបិយប័ណ្ណ",
    Notifications: "ការជូនដំណឹង",
    purchaseText: "មុនពេលទិញផលិតផល លុបចូលគ្នានឹងមាន",
    code: "កូដ",
    discount: "បញ្ចុះតំលៃ %",
    actions: "សកម្មភាព",
    totalOrders: "ចំនួន​សរុប​នៃ​ការ​បញ្ជាទិញ",
    totalUsers: "ចំនួន​អ្នក​ប្រើ​ប្រាស់​សរុប",
    totalSales: "ចំនួន​សរុប​នៃ​ការ​លក់",
    averageTotal: "ការ​វាយ​តម្លៃ​មធ្យម/ការ​វាយ​តម្លៃ​សរុប",
    filterGraph: "តម្រង​តារាង​តាម​រូបភាព",
    startingDate: "ថ្ងៃ​ចាប់​ផ្តើម",
    submit: "ដាក់​ស្នើ",
    overview: "ទិដ្ឋភាពសរុប",
    performance: "លក្ខណៈសម្បត្តិ",
    endingDate: "កាលបរិច្ឆេទបញ្ចប់",
    salesValue: "តម្លៃលក់",
    salesAmount: "ចំនួនលក់",
    orderCount: "ចំនួនបញ្ជាទិញ",
    categoryUpdated: "បានធ្វើបច្ចុប្បន្នភាពប្រភេទដោយជោគជ័យ",
    categoryAdded: "បានបន្ថែមប្រភេទដោយជោគជ័យ",
    actionFailed: "សកម្មភាពបានបរាជ័យ។ សូមព្យាយាមម្តងទៀត",
    egBreakfast: "ឧទាហរណ៍ ពេលវេលាបាល់",
    egAllHappiness: "ឧទាហរណ៍ សុខភាពទាំងអស់គ្នាត្រូវបានលូតលាស់ដោយអារម្មណ៍លាងលាស់នៅលើមាតិកាពេញចិត្តបាន",
    newAddon: 'កម្មវិធី​បន្ថែម​ថ្មី',
    uniqueTitle: 'ចំណងជើងត្រូវតែមានបន្តិច',
    egSmall: 'ឧទាហរណ៍ តូច',
    foodUpdated: "បានធ្វើឲ្យគ្រឿងផ្សារទាន់សម័យដោយជោគជ័យ",
    foodAdded: "បានបន្ថែមគ្រឿងផ្សារដោយជោគជ័យ",
    Addons: "កម្មវិធីបន្ថែម",
    Options: "ជម្រើស",
    selectOneOption: "ជ្រើសរើសជម្រើសមួយយ៉ាងតិច",
    new: "ថ្មី",
    addRemoveAddons: "បន្ថែម/ដកចេញកម្មវិធីបន្ថែម",
    quantityMinimum: "បរិមាណអប្បបរមា",
    quantityMinText: "ត្រូវតែតិចជាងឬស្មើនឹងអប្បបរមា",
    quantityMaximum: "បរិមាណអតិបរមា",
    quantityMaxText: "ត្រូវតែធំជាងឬស្មើនឹងអតិបរមា",
    egPepsi: "ឧស្សាហកម្ម Pepsi",
    egOptional: "ឧស្សាហកម្ម ជម្រើស",
    option: "ជម្រើស",
    mustBeANumber: "ត្រូវតែជាលេខ",
    egTextOption: "ឧស្សាហកម្ម: 90.25",
    saved: "បានរក្សាទុក",
    errorOccured: "មានកំហុសកើតឡើងនៅពេលរក្សាទុក។ សូមព្យាយាមម្តងទៀត",
    discounted: "ការបញ្ចុះតម្លៃ",
    eg99: "ឧស្សាហកម្ម 9.99",
    eg9: "ឧស្សាហកម្ម 9",
    filterByOrderId: "តម្រៀបតាមលេខសម្គាល់ការបញ្ជាទិញ",
    datetime: "កាលបរិច្ឆេទនិងម៉ោង",
    stock: "ស្ថិតិ",
    Ratings: "ការវាយតម្លៃ",
    Riders: "អ្នកប៉ាន់",
    addRider: "បន្ថែមអ្នកប៉ាន់",
    username: "ឈ្មោះអ្នកប្រើប្រាស់",
    available: "មានស្រាប់",
    riderUpdatedText: "បានធ្វើបច្ចុប្បន្នភាពអ្នកប៉ាន់ដោយជោគជ័យ",
    riderAddedText: "បានបន្ថែមអ្នកប៉ាន់ដោយជោគជ័យ",
    editRider: "កែសម្រួលអ្នកប៉ាន់",
    Option: "ជម្រើស",
    minimum: "អប្បបរមា",
    maximum: "អតិបរមា",
    Coupons: "គូប៉ុង",
    couponUpdatedText: "ប៉ុងបញ្ចូលទាន់ហេតុបានកំណត់ហើយ",
    couponAddedText: "ប៉ុងបញ្ចូលទាន់ហេតុបានបន្ថែម",
    actionFailedError: "សកម្មភាពបានបរាជ័យ។ សូមព្យាយាមម្ដងទៀត",
    editCoupon: "កែប្រែប៉ុង",
    addCoupon: "បន្ថែមប៉ុង",
    couponCode: "លេខកូដប៉ុង",
    discountPercent: "ភាគរយបញ្ចុះតំលៃ",
    btw1And100: "ពី ១ ទៅ ១០០",
    enableDisable: "បើក/បិទ",
    egSales: "ឧទាហរណ៍: SALE50",
    body: 'តួនាទី',
    egHello: "ឧទាហរណ៍ សួស្ដី",
    send: "បញ្ជូន",
    ReasonIfRejected: 'ហេតុប្រើប្រាស់បញ្ហា ប្រសិនបើត្រូវបានបដិសេធ',
    Accepted: 'បានទទួលយក',
    Accept: 'ទទួលយក',
    Cancelled: 'បានលើកលែង',
    Cancel: 'បោះបង់',
    Assign: 'ថ្នាំ',
    ShowHide: 'បង្ហាញ/លាក់',
    PaymentMethod: 'វិធីសាស្ត្រទូទាត់',
    PaidAmount: 'ចំនួនទូទាត់',
    AvailableRiders: 'អ្នកជួលអាហាររបស់អាហារ',
    Rider: 'អ្នកជួល',
    AssignedTo: 'បានថ្នាំទៅ',
    SelectStatus: 'ជ្រើសរើសស្ថានភាព',
    OrderStatus: 'ស្ថានភាពបញ្ជាទិញ',
    CurrentStatus: 'ស្ថានភាពបច្ចុប្បន្ន',
    PaymentStatus: 'ស្ថានភាពទូទាត់',
    Customer: 'អតិថិជន',
    OrderDetails: 'ព័ត៌មានលម្អិតអំពីបញ្ជាទិញ',
    Charges: 'ថ្លៃអតិថិជន',
    DeliveryCharges: 'ថ្លៃដឹកជញ្ជាំង',
    Subtotal: 'សរុបរួម',
    Total: 'សរុប',
    Admin: 'មេរៀន',
    Stripe: 'ស្ត្រាព័ត៌មាន',
    PHRiderName: 'ជន ដូ',
    PHUserName: 'JohnDoe1122',
  },
};

export default km;
