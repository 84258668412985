const fr = {
  translation: {
    "Sign in credentials": "Identifiez-vous",
    "Sign in": "se connecter",
    "Add Category": "ajouter une catégorie",
    Title: "Titre",
    Description: "La description",
    Add: "Ajouter",
    Remove: "Retirer",
    "Add/Remove": "Ajouter enlever",
    Success: "Succès",
    Danger: "Danger",
    Categories: "Les catégories",
    Image: "Image",
    Header: "Entête",
    Loading: "Chargement",
    Error: "Erreur",
    Edit: "modifier",
    Delete: "Effacer",
    "Edit Category": "Modifier la catégorie",
    Save: "sauvegarder",
    "OrderID prefix": "Préfixe OrderID",
    Order: "Ordre",
    Email: "Email",
    Password: "Mot de passe",
    Enable: "Activer",
    Disable: "Désactiver",
    "Enable/Disable": "Activer désactiver",
    Saving: "Économie",
    Paypal: "Pay Pal",
    "Client ID": "identité du client",
    "Client Secret": "Secret du client",
    Sandbox: "bac à sable",
    "Publishable Key": "Clé publiable",
    "Secret Key": "Clef secrète",
    "Delivery Charges": "Frais de livraison",
    Price: "Prix",
    "Add Food": "Ajouter de la nourriture",
    Category: "Catégorie",
    Select: "Sélectionner",
    Variations: "Variations",
    Type: "Type",
    "Food Image": "Image de nourriture",
    Foods: "nourriture",
    "Edit Food": "Modifier un aliment",
    OrderID: "Numéro de commande",
    Orders: "Ordres",
    Name: "prénom",
    Items: "Articles",
    Payment: "Paiement",
    Status: "Statut",
    Review: "La revue",
    "Mark as": "Marquer comme",
    Users: "Utilisateurs",
    Phone: "Téléphone",
    Address: "Adresse",
    "2019-20": "2019-20",
    "About Us": "À propos de nous",
    Blog: "Blog",
    Welcome: "Bienvenue",
    Logout: "Connectez - Out",
    Dashboard: "Tableau de bord",
    Configuration: "Configuration",
    Login: "S'identifier",
    Food: "Aliments",
    "Character limit of max length 50":
      "Limite de caractères de longueur maximale 50",
    "Character limit of max length 15":
      "Limite de caractères de longueur maximale 15",
    "Character limit of max length 60":
      "Limite de caractères de longueur maximale 60",
    "Character limit of max length 20":
      "Limite de caractères de longueur maximale 20",
    "Character limit of max length 140":
      "Limite de caractères de longueur maximale 140",
    Currency: "Devise",
    "Currency Code": "Code de devise",
    "Currency Symbol": "Symbole de la monnaie",
    Notifications: "Notifications",
    purchaseText: "La fonction de suppression sera disponible après l'achat du produit",
    code: "Code",
    discount: "Réduction %",
    totalOrders: "Total des commandes",
    totalUsers: "Total des utilisateurs",
    totalSales: "Total des ventes",
    averageTotal: "Note moyenne/Total des notes",
    filterGraph: "Filtrer le graphique",
    startingDate: "Date de début",
    submit: "Soumettre",
    overview: "Vue d'ensemble",
    performance: "Performance",
    endingDate: "Date de fin",
    salesValue: "Valeur des ventes",
    salesAmount: "Montant des ventes",
    orderCount: "Nombre de commandes",
    categoryUpdated: "Catégorie mise à jour avec succès",
    categoryAdded: "Catégorie ajoutée avec succès",
    actionFailed: "Action échouée. Veuillez réessayer",
    egBreakfast: "par exemple, le petit-déjeuner",
    egAllHappiness: "par exemple, tout le bonheur dépend d'un petit-déjeuner tranquille.",
    newAddon: 'Nouvel ajout',
    uniqueTitle: 'Le titre doit être unique',
    egSmall: 'par exemple Petit',
    foodUpdated: "Aliment mis à jour avec succès",
    foodAdded: "Aliment ajouté avec succès",
    Addons: "Extensions",
    Options: "Options",
    selectOneOption: "Sélectionnez au moins une option",
    new: "Nouveau",
    addRemoveAddons: "Ajouter/Supprimer des extensions",
    quantityMinimum: "Quantité minimale",
    quantityMinText: "Doit être inférieur ou égal au maximum",
    quantityMaximum: "Quantité maximale",
    quantityMaxText: "Doit être supérieur ou égal au maximum",
    egPepsi: "par exemple : Pepsi",
    egOptional: "par exemple : Optionnel",
    option: "Option",
    mustBeANumber: "Doit être un nombre",
    egTextOption: "par exemple : 90.25",
    saved: "Enregistré",
    errorOccured: "Une erreur est survenue lors de l'enregistrement. Veuillez réessayer",
    discounted: "Remisé",
    eg99: "par exemple 9.99",
    eg9: "par exemple 9",
    filterByOrderId: "Filtrer par numéro de commande",
    datetime: "Date et heure",
    stock: "Stock",
    Ratings: "Évaluations",
    Riders: "Cavaliers",
    addRider: "Ajouter un cavalier",
    username: "Nom d'utilisateur",
    available: "Disponible",
    riderUpdatedText: "Cavalier mis à jour avec succès",
    riderAddedText: "Cavalier ajouté avec succès",
    actions: "Action",
    editRider: "Modifier le cavalier",
    Option: "Option",
    minimum: "Minimum",
    maximum: "Maximum",
    Coupons: "Coupons",
    couponUpdatedText: "Coupon mis à jour",
    couponAddedText: "Coupon ajouté",
    actionFailedError: "Action échouée. Veuillez réessayer",
    editCoupon: "Modifier le coupon",
    addCoupon: "Ajouter un coupon",
    couponCode: "Code de coupon",
    discountPercent: "Pourcentage de réduction",
    btw1And100: "Entre 1 et 100",
    enableDisable: "Activé/Désactivé",
    egSales: "ex. SALE50",
    body: 'Corps',
    egHello: "par exemple, Bonjour",
    send: "Envoyer",
    ReasonIfRejected: 'Raison si refusé',
    Accepted: 'Accepté',
    Accept: 'Accepter',
    Cancelled: 'Annulé',
    Cancel: 'Annuler',
    Assign: 'Attribuer',
    ShowHide: 'Afficher/Masquer',
    PaymentMethod: 'Méthode de paiement',
    PaidAmount: 'Montant payé',
    AvailableRiders: 'Coursiers disponibles',
    Rider: 'Coursier',
    AssignedTo: 'Assigné à',
    SelectStatus: 'Sélectionner le statut',
    OrderStatus: 'Statut de commande',
    CurrentStatus: 'Statut actuel',
    PaymentStatus: 'Statut de paiement',
    Customer: 'Client',
    OrderDetails: 'Détails de commande',
    Charges: 'Frais',
    DeliveryCharges: 'Frais de livraison',
    Subtotal: 'Sous-total',
    Total: 'Total',
    Admin: 'Admin',
    Stripe: 'Stripe',
    PHRiderName: 'Jean Dupont',
    PHUserName: 'JeanDupont1122',
  },
};

export default fr;
