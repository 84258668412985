const en = {
  translation: {
    "Sign in credentials": "Sign in credentials",
    "Sign in": "Sign in",
    "Add Category": "Add Category",
    Title: "Title",
    Description: "Description",
    Add: "Add",
    Remove: "Remove",
    "Add/Remove": "Add/Remove",
    Success: "Success",
    Danger: "Danger",
    Categories: "Categories",
    Image: "Image",
    Header: "Header",
    Loading: "Loading",
    Error: "Error",
    Edit: "Edit",
    Delete: "Delete",
    "Edit Category": "Edit Category",
    Save: "Save",
    "OrderID prefix": "OrderID prefix",
    Order: "Order",
    Email: "Email",
    Password: "Password",
    Enable: "Enable",
    Disable: "Disable",
    "Enable/Disable": "Enable/Disable",
    Saving: "Saving",
    Paypal: "Paypal",
    "Client ID": "Client ID",
    "Client Secret": "Client Secret",
    Sandbox: "Sandbox",
    "Publishable Key": "Publishable Key",
    "Secret Key": "Secret Key",
    "Delivery Charges": "Delivery Charges",
    Price: "Price",
    "Add Food": "Add Food",
    Category: "Category",
    Select: "Select",
    Variations: "Variations",
    Type: "Type",
    "Food Image": "Food Image",
    Foods: "Foods",
    "Edit Food": "Edit Food",
    OrderID: "Order ID",
    Orders: "Orders",
    Name: "Name",
    Items: "Items",
    Payment: "Payment",
    Status: "Status",
    Review: "Review",
    "Mark as": "Mark as",
    Users: "Users",
    Phone: "Phone",
    Address: "Address",
    "2019-20": "2019-20",
    "About Us": "About Us",
    Blog: "Blog",
    Welcome: "Welcome",
    Logout: "Logout",
    Dashboard: "Dashboard",
    Configuration: "Configuration",
    Login: "Login",
    Food: "Food",
    "Character limit of max length 50": "Character limit of max length 50",
    "Character limit of max length 15": "Character limit of max length 15",
    "Character limit of max length 60": "Character limit of max length 60",
    "Character limit of max length 20": "Character limit of max length 20",
    "Character limit of max length 140": "Character limit of max length 140",
    Currency: "Currency",
    "Currency Code": "Currency Code",
    "Currency Symbol": "Currency Symbol",
    Notifications: "Notifications",
    purchaseText: "Delete feature will available after purchasing product",
    code: "Code",
    discount: "Discount %",
    totalOrders: "Total Orders",
    totalUsers: "Total Users",
    totalSales: "Total Sales",
    averageTotal: " Average Ratings/Total Ratings",
    filterGraph: "Filter Graph",
    startingDate: "Starting Date",
    submit: "Submit",
    overview: "Overview",
    performance: "Performance",
    endingDate: "Ending Date",
    salesValue: "Sales value",
    salesAmount: "Sales Amount",
    orderCount: "Order count",
    categoryUpdated: "Category updated successfully",
    categoryAdded: "Category added successfully",
    actionFailed: "Action failed. Please Try again",
    egBreakfast: "e.g Breakfast",
    egAllHappiness: "e.g All happiness depends on leisurely breakfast.",
    newAddon: 'New Addon',
    uniqueTitle: 'Title must be unqiue',
    egSmall: 'e.g Small',
    foodUpdated: "Food updated successfully",
    foodAdded: "Food added successfully",
    Addons: "Addons",
    Options: "Options",
    selectOneOption: "Select atleast one Option",
    new: "New",
    addRemoveAddons: "Add/Remove Addons",
    quantityMinimum: "Quantity Minimum",
    quantityMinText: "Must be a less than or equal to Maximum",
    quantityMaximum: "Quantity Maximum",
    quantityMaxText: "Must be a greater than or equal to Maximum",
    egPepsi: "e.g Pepsi",
    egOptional: "e.g Optional",
    option: "Option",
    mustBeANumber: "Must be a number",
    egTextOption: "e.g 90.25",
    saved: "Saved",
    errorOccured: "An error occured while saving,Try again",
    discounted: "Discounted",
    eg99: "e.g 9.99",
    eg9: "e.g 9",
    filterByOrderId: "Filter By Order Id",
    datetime: "Datetime",
    stock: "Stock",
    Ratings: "Ratings",
    Riders: "Riders",
    addRider: "Add Rider",
    username: "Username",
    available: "Available",
    riderUpdatedText: "Rider updated successfully",
    riderAddedText: "Rider added successfully",
    actions: "Action",
    editRider: "Edit Rider",
    Option: "Option",
    minimum: "Minimum",
    maximum: "Maximum",
    Coupons:"Coupons",
    couponUpdatedText: "Coupon updated",
    couponAddedText: "Coupon added",
    actionFailedError: "Action failed. Please Try again",
    editCoupon: "Edit Coupon",
    addCoupon: "Add Coupon",
    couponCode: "Coupon Code",
    discountPercent: "Discount Percent",
    btw1And100: "Between 1 and 100",
    enableDisable: "Enabled/Disabled",
    egSales: "e.g SALE50",
    body: 'Body',
    egHello: "e.g Hello",
    send: "Send",
    ReasonIfRejected: 'Reason if rejected',
    Accepted: 'Accepted',
    Accept: 'Accept',
    Cancelled: 'Cancelled',
    Cancel: 'Cancel',
    Assign: 'Assign',
    ShowHide: 'Show/Hide',
    PaymentMethod: 'Payment Method',
    PaidAmount: 'Paid Amount',
    AvailableRiders: 'Available Riders',
    Rider: 'Rider',
    AssignedTo: 'Assigned To',
    SelectStatus: 'Select Status',
    OrderStatus: 'Order Status',
    CurrentStatus: 'CurrentStatus',
    PaymentStatus: 'Payment Status',
    Customer: 'Customer',
    OrderDetails: 'Order Details',
    Charges: 'Charges',
    DeliveryCharges: 'Delivery Charges',
    Subtotal: 'Subtotal',
    Total: 'Total',
    Admin: 'Admin',
    Stripe: 'Stripe',
    PHRiderName : 'John Doe',
    PHUserName: 'JohnDoe1122'
  },
};

export default en;
